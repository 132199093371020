import React from 'react';
import LoadingSpinner from '../Spinner'; 


function Detail({ tournament }) {
    if (!tournament) {
        return <LoadingSpinner />; 
    }

    const { gameName, description, startAt, tournamentType} = tournament;

    return (
        <div>
            <br />
            <p className='label-torneos'>Juego y Región</p>
            <p className='content-torneos'>{gameName}</p>
            <hr />
            <p className='label-torneos'>Descripción</p>
            <p className='content-torneos'>{description || "No especificado"}</p>
            <hr />
            <p className='label-torneos'>Fecha</p>
            <p className='content-torneos'>{startAt ? new Date(startAt).toLocaleDateString() : "No especificada"}</p>
            <hr />
            <p className='label-torneos'>Formato de Juego</p>
            <p className='content-torneos'>{tournamentType || "No especificado"}</p>
            
        </div>
    );
}

export default Detail;
