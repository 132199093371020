import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSpinner from '../Spinner'; // Importar el spinner

function Results({ tournament }) {
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Estado de carga

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tournaments/${tournament.id}/results`);
                setResults(response.data || []);
            } catch (error) {
                console.error('Error al obtener datos de resultados:', error);
                setError('Error al obtener datos de resultados');
            } finally {
                setLoading(false); // Desactivar el estado de carga
            }
        };

        if (tournament) {
            fetchResults();
        }
    }, [tournament]);

    if (loading) {
        return <LoadingSpinner />; // Mostrar spinner mientras se cargan los datos
    }

    if (!tournament) {
        return <p>No hay datos disponibles para los resultados.</p>;
    }

    const { state } = tournament;

    let tournamentWinner = '🏆 No disponible';
    if (results.length > 0) {
        const lastResult = results[results.length - 3];
        const emojis = ['🥇', '🥈', '🥉'];
        tournamentWinner = `${emojis[0]} ${lastResult}` || '🏆 No disponible';
    }

    return (
        <div>
            {error ? (
                <p>{error}</p>
            ) : (
                <div>
                    {loading && <LoadingSpinner />}
                    {results.length > 0 ? (
                        <div>
                            <br />
                            <p className='label-torneos'>Estado del torneo</p>
                            <p className='tag-torneos'>{state} ⭐</p>
                            <hr />
                            <p className='label-torneos'>Ganador del Torneo</p>
                            <p className='content-torneos'>{tournamentWinner}</p>
                            <hr />
                            <p className='label-torneos'>Los tres primeros ganadores</p>
                            <div className='content-torneos'>
                                {results.map((result, index) => (
                                    <p key={index}>
                                        {index === 0 && '🥇'}
                                        {index === 1 && '🥈'}
                                        {index === 2 && '🥉'}
                                        {result}
                                    </p>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p>No hay resultados disponibles para este torneo.</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default Results;
