import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const ChampList = ({ champs }) => {
  const renderTooltip = (name) => (
    <Tooltip id="button-tooltip">{name}</Tooltip>
  );


  const sortedTiers = ['S+', 'S', 'A', 'B', 'C'];
  const champsByTier = champs.reduce((acc, champ) => {
    const lane = champ.lanes && champ.lanes.length > 0 ? champ.lanes[0] : { lane: 'Other', tier: 'Other' };
    const tier = typeof lane.tier === 'string' && lane.tier.length > 0 ? lane.tier : 'Other';
  
    if (!acc[tier]) {
      acc[tier] = [];
    }
  
    // Verificamos si el campeón ya está en esa línea y evitamos duplicados
    if (!acc[tier].some((c) => c.id === champ.id)) {
      acc[tier].push(champ);
    }
  
    return acc;
  }, {});
  
  
  const getTierStyle = (tier) => {
    switch (tier) {
      case 'S+':
        return {
          backgroundColor: '#9926FC',
          borderRadius: '8px 0px 0px 8px',
          background: 'linear-gradient(180deg, #9926FC 0%, #A621DB 41.15%, #FF0202 100%)',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
      case 'S':
        return {
          backgroundColor: '#FF0202',
          borderRadius: '8px 0px 0px 8px',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
      case 'A':
        return {
          backgroundColor: '#FF7A00',
          borderRadius: '8px 0px 0px 8px',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
      case 'B':
        return {
          backgroundColor: '#FFB800',
          borderRadius: '8px 0px 0px 8px',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
      case 'C':
        return {
          backgroundColor: '#00BB9B',
          borderRadius: '8px 0px 0px 8px',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
      default:
        return {
          backgroundColor: '#1D1D1D',
          borderRadius: '8px 0px 0px 8px',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
    }
  };

  return (
    <Container fluid>
      {sortedTiers.map((tier) => (
        (champsByTier[tier] && champsByTier[tier].length > 0) ? (
          <Row key={tier} className="mb-3 champ-list">
            <Col xs={2} md={1} lg={1} className={`tier-column ${tier.toLowerCase()}  align-items-center `} style={getTierStyle(tier)}>
              <span className='text-api'>{tier}</span>
            </Col>
            <Col xs={10} md={11} lg={11}>
              <Row className="  justify-content-start align-items-start">
                {champsByTier[tier].map((champ, index) => (
                  <Col key={champ.id} xs={6} md={4} lg={3} className="champ-container mb-2">
                    <div className="d-flex align-items-start container-api">
                      <div className="mr-3">
                        <img src={champ.avatar} alt={champ.name} className="img-api"/>
                      </div>
                      <div className="div-api">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{show: 250, hide: 400}}
                          overlay={renderTooltip(champ.name)}
                        >
                          <p className="text-api">{champ.name}</p>
                        </OverlayTrigger>
                        {champ.tags && champ.tags.length > 0 ? (
                          <p className="subheding-api">{champ.tags[0]}</p>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                ))}
                {[...Array(4 - champsByTier[tier].length % 4)].map((_, index) => (
                  <Col key={`empty-${index}`} xs={6} md={4} lg={3} className="champ-container mb-2"></Col>
                ))}
              </Row>
            </Col>
          </Row>
        ) : null
      ))}
      {Object.keys(champsByTier).every((tier) => champsByTier[tier].length === 0) && (
        <Row>
          <Col xs={12} className="tier-column" style={{backgroundColor: '#1D1D1D', borderRadius: '8px 0px 0px 8px', color: '#fff'}}>
            <span className='text-api'>No se encontraron resultados</span>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ChampList;