import Layout from './components/Layout';


const About = () =>{
    return(
    <Layout
    title="About - Cetus Gaming"
    description="Descubre la tier list actualizada para Wild Rift en el parche 4.4C. Encuentra los mejores campeones para escalar y alcanzar Diamante bajo."
     >
    <section style={{ alignItems: 'center' }} >
    <div className="pt-5 pb-5 d-flex">
    <div className="pt-5 pb-5 container">
    <div className="col text-center align-middle align-text-v-lp">
             <h6 className="sub-section-heading text-uppercase">nuestra misión</h6>
             <br/>
             <h2 className="text-uppercase">Somos un equipo de gamers desarrolladores y creativos unidos para crear LaS MEJORES experiencias y BRINDAR A LOS JUGADORES Los MEJORes valores. </h2>
             <br/>
     </div>
     </div>
     </div>
    </section>
    </Layout>
    )
}

export default About;