import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Detail from './components/tournament/Detail';
import Rules from './components/tournament/Rules';
import Bracket from './components/tournament/Bracket';
import Results from './components/tournament/Results';
import LoadingSpinner from './components/Spinner';


function Tournaments() {
    const [activeTournaments, setActiveTournaments] = useState([]);
    const [pastTournaments, setPastTournaments] = useState([]);
    const [progressTournaments, setProgressTournaments] = useState([]);

    const [loading, setLoading] = useState(true); // Estado de carga
    const [error, setError] = useState(null);
    const [selectedTournamentId, setSelectedTournamentId] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");

    useEffect(() => {
        async function fetchTournaments() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tournaments`);
                const tournamentList = Object.values(response.data);

                // Ordenar los torneos por fecha (asumiendo que tienen un campo `createdAt`)
                tournamentList.sort((a, b) => {
                    // Prioritize non-archived tournaments
                    if (a.tournament.state !== 'archived' && b.tournament.state === 'archived') {
                      return -1;
                    } else if (a.tournament.state === 'archived' && b.tournament.state !== 'archived') {
                      return 1;
                    }
                    // Sort by creation date (newest first) if states are the same
                    return new Date(b.tournament.createdAt) - new Date(a.tournament.createdAt);
                  });

                  const nonArchivedTournaments = tournamentList.filter(tournament => {
                    const state = tournament?.tournament?.state || 'pending'; 
                    return state !== 'archived';
                });


                const active = nonArchivedTournaments.filter(tournament => 
                    tournament?.tournament?.state === 'pending' || 
                    tournament?.tournament?.state === 'open'
                );

            
                const past = nonArchivedTournaments.filter(tournament => 
                    tournament?.tournament?.state === 'complete' || 
                    tournament?.tournament?.state === 'closed'
                );
            
                const progress = nonArchivedTournaments.filter(tournament => 
                    tournament?.tournament?.state === 'underway' || 
                    tournament?.tournament?.state === 'group_stages_underway'
                );

                setActiveTournaments(active);
                setPastTournaments(past);
                setProgressTournaments(progress);


                // Preseleccionar el primer torneo activo
                if (active.length > 0) {
                    setSelectedTournamentId(active[0]?.tournament?.id);
                }

                // Establecer todos los acordeones como abiertos por defecto
                setAccordionOpen(new Array(active.length).fill(true));
            } catch (error) {
                //console.error('Coming soon parcero', error);
                //setError('Error al obtener torneos. Inténtelo más tarde.');
            } finally {
                setLoading(false); // Desactivar el estado de carga
            }
        }

        fetchTournaments();
    }, []);

    useEffect(() => {
        // Establecer todos los acordeones de torneos activos como abiertos
        setAccordionOpen(new Array(activeTournaments.length).fill(true));
    }, [activeTournaments]);

    const handleRegistration = async (event) => {
        event.preventDefault();
        const tournamentId = event.target.formTournament.value;
        const tag = event.target.formTag.value;
        const email = event.target.formEmail.value;
        const country = event.target.formPais.value;
        const name = event.target.formName.value;

        if (!selectedTournamentId) {
            console.error('Debe seleccionar un torneo para inscribirse.');
            return;
        }

        try {
            await handleRegisterParticipant(tournamentId, tag, email, country, name);
            setShowToast(true);
            event.target.reset();
        } catch (error) {
            console.error('Error al inscribir participante:', error);
        }
    };

    const handleRegisterParticipant = async (tournamentId, tag, email, country, name) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/tournaments/${tournamentId}/participants`, { tournamentId, tag, email, country, name });
        } catch (error) {
            console.error('Error al inscribir participante:', error);
        }
    };

    const handleTournamentSelection = (event) => {
        const selectedId = event.target.value;
        setSelectedTournamentId(selectedId);
    };

    const handleCountrySelection = (event) => {
        setSelectedCountry(event.target.value); // Actualizar el estado con el país seleccionado
    };
    
    const toggleToast = () => setShowToast(!showToast);

    return (
        <div>
            <div className="header-t-section">
                <div className="header-t">
                    <img src="/img/header-tournaments.webp" className="bg-sorteo-lp " alt="header" />
                    <br />
                    <br />

                    <h6 className='text-spacing'>CETUS GAMING</h6>
                    <h5 className='text-spacing-2'>PRESENTA</h5>
                </div>
                <div>
                    <h1 className='min-line'>Torneo</h1>
                    <h2 className='min-line'>1 vs 1</h2>
                    <h1 className='min-line'>FREE ELO</h1>
                    <Button href="https://discord.gg/rJfDUgWUs9" target="_blank" rel="noreferrer" className="btn-community-sus glow-on-hover">
                        UNIRME A LA COMUNIDAD
                    </Button>
                    <br />
                    <br />
                    <a className='know-more' href="#formTag">Conocer más</a>
                </div>
            </div>
            
            {loading && <LoadingSpinner />}
            {error && <p>Error: {error}</p>}
            {!loading && (activeTournaments.length > 0 || pastTournaments.length > 0 || progressTournaments.length > 0) ? (
           
            <Container>
                {loading && <LoadingSpinner />}
                {error && <p>Error: {error}</p>}
                {!loading && activeTournaments.length > 0 && (
                    <div>
                        <h2 className='titles-torneos padding-b-t'>Información de los torneos activos</h2>
                        <Accordion defaultActiveKey="0" className='height-bg-form'>
                            {activeTournaments.map((tournament, index) => {
                                const tournamentData = tournament?.tournament;

                                return (
                                    <Accordion.Item eventKey={index.toString()} key={tournamentData?.id} active={accordionOpen[index]}>
                                        <Accordion.Header onClick={() => {
                                            const newAccordionOpen = [...accordionOpen];
                                            newAccordionOpen[index] = !newAccordionOpen[index];
                                            setAccordionOpen(newAccordionOpen);
                                        }}>
                                            {tournamentData?.name}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Tabs defaultActiveKey="detail" className="mb-3">
                                                <Tab eventKey="detail" title="Detalle" className='color-nav-tor'>
                                                    <Detail  tournament={tournamentData } />
                                                </Tab>
                                                <Tab eventKey="rules" title="Reglas">
                                                    <Rules tournament={tournamentData} />
                                                </Tab>
                                                <Tab eventKey="bracket" title="Cuadros">
                                                    <Bracket tournamentId={tournamentData?.id} tournament={tournamentData} />
                                                </Tab>
                                                <Tab eventKey="results" title="Resultados">
                                                    <Results tournament={tournamentData} />
                                                </Tab>
                                            </Tabs>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                        <h2 className='titles-torneos padding-b-t'>Inscribirse en {activeTournaments.find(tournament => tournament.tournament.id === selectedTournamentId)?.tournament.name}</h2>
                        <div>
                            <img className='bg-sorteo-lp height-bg-form' src="/img/bg-fomr.webp" alt="" style={{ maxWidth: '1296px' }} />
                            <Row>
                                <Col xs={6} md={6} lg={6}>
                                    <Container className="registration-form">
                                        <div>
                                            <br />
                                            <h3>Completa la siguiente información</h3>
                                            <Form onSubmit={handleRegistration}>
                                                <Form.Group controlId="formTournament">
                                                    <Form.Label>Seleccionar torneo</Form.Label>
                                                    <Form.Control name="tournamentId" as="select" value={selectedTournamentId} onChange={handleTournamentSelection}>
                                                        {activeTournaments.map(tournament => (
                                                            <option key={tournament.tournament.id} value={tournament.tournament.id}>
                                                                {tournament.tournament.name}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formTag">
                                                    <Form.Label>Usuario de Juego #Tag</Form.Label>
                                                    <Form.Control name='tag' type="text" placeholder="Usuario#Tag" required />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control name='email' type="email" placeholder="Ingresa tu email" required />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formPais">
                                                    <Form.Label>Selecciona tu país</Form.Label>
                                                 <Form.Control name='country' as="select" value={selectedCountry} onChange={handleCountrySelection}>
                            <option value="">Selecciona</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-bissau">Guinea-bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                            <option value="Korea, Republic of">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-leste">Timor-leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                                                </Form.Control>
                                              </Form.Group>
                                                     <br />
                                                     <Form.Group controlId="formName">
                                                    <Form.Label>Tu nombre</Form.Label>
                                                    <Form.Control name='name' type="text" placeholder="Tu nombre" required />
                                                </Form.Group>
                                                <br />
                                                <Button type="submit" className="mt-2 btn-community-sus">
                                                    Inscribirse
                                                </Button>
                                            </Form>
                                        </div>
                                        <br />
                                        <Toast show={showToast} onClose={toggleToast}>
                                            <Toast.Header>
                                                <strong className="me-auto">Mensaje</strong>
                                            </Toast.Header>
                                            <Toast.Body>¡Te has inscrito exitosamente en el torneo!✅</Toast.Body>
                                        </Toast>
                                    </Container>
                                </Col>
                                <Col xs={6} md={6} lg={6} className='mb-3 pb-3'>
                                    <img src="/img/champion_overview_jinx_.webp" alt="" style={{ maxWidth: '630px' }} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
                {!loading && progressTournaments.length > 0 && (
                    <div>
                        <br />
                        <h2 className='titles-torneos'>Información de los torneos en curso</h2>
                        <Accordion>
                            {progressTournaments.map((tournament, index) => {
                                const tournamentData = tournament?.tournament;

                                return (
                                    <Accordion.Item className="bg-dark" eventKey={index.toString()} key={tournamentData?.id}>
                                        <Accordion.Header>{tournamentData?.name}</Accordion.Header>
                                        <Accordion.Body>
                                            <Tabs defaultActiveKey="detail" className="mb-3">
                                                <Tab eventKey="detail" title="Detalle">
                                                    <Detail tournament={tournamentData} />
                                                </Tab>
                                                <Tab eventKey="rules" title="Reglas">
                                                    <Rules tournament={tournamentData} />
                                                </Tab>
                                                <Tab eventKey="bracket" title="Cuadros">
                                                    <Bracket tournamentId={tournamentData?.id} />
                                                </Tab>
                                                <Tab eventKey="results" title="Resultados">
                                                    <Results tournament={tournamentData} />
                                                </Tab>
                                            </Tabs>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    </div>
                )}  


                {!loading && pastTournaments.length > 0 && (
                    <div>
                    <br />

                        <h2 className='titles-torneos pb-4'>Información de los torneos anteriores</h2>
                        <Accordion>
                            {pastTournaments.map((tournament, index) => {
                                const tournamentData = tournament?.tournament;

                                return (
                                    <Accordion.Item className="bg-dark" eventKey={index.toString()} key={tournamentData?.id}>
                                        <Accordion.Header>{tournamentData?.name}</Accordion.Header>
                                        <Accordion.Body>
                                            <Tabs defaultActiveKey="detail" className="mb-3">
                                                <Tab eventKey="detail" title="Detalle">
                                                    <Detail tournament={tournamentData} />
                                                </Tab>
                                                <Tab eventKey="rules" title="Reglas">
                                                    <Rules tournament={tournamentData} />
                                                </Tab>
                                                <Tab eventKey="bracket" title="Cuadros">
                                                    <Bracket tournamentId={tournamentData?.id} />
                                                </Tab>
                                                <Tab eventKey="results" title="Resultados">
                                                    <Results tournament={tournamentData} />
                                                </Tab>
                                            </Tabs>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    </div>
                )}
            </Container>
            ) : (
                <div className="text-center">
                    <br />
                    <h2>Pronto se habilitarán los torneos</h2>
                    <img className='img-jinx-resp' src="/img/champion_overview_jinx_.webp" alt="Jinx" style={{ maxWidth: '630px' }} />

                </div>
            )}
        </div>
    );
}

export default Tournaments;