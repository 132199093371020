import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Importa NavLink desde react-router-dom
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Badge from 'react-bootstrap/Badge';

import SuscribeteModal from '../components/Suscribete';

const NavBar = () => {
  const [showSuscribeteModal, setShowSuscribeteModal] = useState(false);
  const isNewTierlist = true;

  const handleShowSuscribeteModal = () => setShowSuscribeteModal(true);
  const handleCloseSuscribeteModal = () => setShowSuscribeteModal(false);

  const handleSuscribete = () => {
    console.log('Usuario suscrito');
  };

  return (
    <>
      <Navbar expand="lg" className="navbar navbar-expand-lg p-l-r" data-bs-theme="dark">
        <Container style={{ maxWidth: '1100px', alignItems: 'center' }}>
        <Navbar.Brand href="/" className="d-inline-block align-top">
              <img className="logo" src="img/cetus-logo.png" alt="logo-cetus" />
            </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav>
              <NavLink to="/cetus" className="nav-link" activeclassname="active">
                Inicio
              </NavLink>
              <NavLink to="/tierlist" className="nav-link" activeclassname="active">
                Tier List
                {isNewTierlist && <Badge className="ms-1 badgec">New</Badge>}
              </NavLink>
              <NavLink to="/tournaments" className="nav-link" activeclassname="active">
                Torneos
              </NavLink>
              <NavLink to="/blog" className="nav-link" activeclassname="active">
                Blog
              </NavLink>
              <NavLink to="/about" className="nav-link" activeclassname="active">
                Nosotros
              </NavLink>
              <Nav.Link className="cta-community btn-community-sus" onClick={handleShowSuscribeteModal }>Suscríbete</Nav.Link>
              <Nav.Link href="https://discord.gg/rJfDUgWUs9" target="_blank" rel="noreferrer" className="btn btn-community glow-on-hover ">Comunidad<img className="discord-icon" src="img/discord.svg" alt=""/></Nav.Link>
           </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showSuscribeteModal && (<SuscribeteModal onClose={handleCloseSuscribeteModal} onSuscribete={handleSuscribete} />
      )}
    </>
  );
}

export default NavBar;

