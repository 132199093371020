import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSpinner from '../Spinner'; // Importar el spinner

function Bracket({ tournamentId }) {
    const [iframeUrl, setIframeUrl] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Estado de carga
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

    useEffect(() => {
        const fetchIframeLink = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/iframe-link/${tournamentId}`);
                if (response.data.iframeLink) {
                    setIframeUrl(response.data.iframeLink);
                }
            } catch (error) {
                setError('Error al obtener el enlace de iframe. Por favor, intenta de nuevo más tarde.');
            } finally {
                setIsInitialLoadComplete(true);
                setLoading(false); 
            }
        };

        fetchIframeLink();
    }, [tournamentId]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    if (!isInitialLoadComplete) {
        return <LoadingSpinner />
    }

    return (
<div>
    {loading && <LoadingSpinner /> }
    {error && <p>{error}</p>}
    {iframeUrl && (
        <iframe src={iframeUrl} title="Nombre único para el título del iframe" width="100%" height="500" frameBorder="0" scrolling="auto" allowTransparency="true"/>
    )}
</div>
    );
}

export default Bracket;
