import React from 'react';

import  Button  from 'react-bootstrap/Button';
import Layout from './components/Layout';

const Home = () =>{
    return(
      <Layout
      title="Cetus Gaming"
      description="Cetus Gaming te invita a formar parte de la comunidad de Wild Rift y a participar en nuestros sorteos para ganar increíbles SKINS. Mantente al día con las últimas noticias, tips y recomendaciones para mejorar tu juego. ¡Regístrate ahora y sé parte de la acción!"
       >
    <section className='bg-grid-home'>
    <div >
    <div className="vertical-align-middle ">
    <div className="header-teemo">
     <h1 className="title_h1">Comunidad de<br/> Wild Rift LAN</h1>
     <Button href="https://discord.gg/rJfDUgWUs9" target="_blank" rel="noreferrer"  className=" btn-community-sus glow-on-hover " >UNIRME Y PARTICIPAR</Button>
     <img src="img/skins.png" className="stckr-skins imagen-temblor-4" alt="skins"/>
     <img src="img/new-friends.png" className="imagen-temblor  stckr-new-friend" alt="wildpass"/>
     <img src="img/tips.png" className="stckr-tips imagen-temblor-3" alt="tips"/>
     <img src="img/wild-pass.png" className="stckr-wildpass imagen-temblor-2 "  alt="newfriends"/>
      <div className="marquee">
      <div>
              <span className="marquee-space">sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦</span>
              </div><br/>
      </div>
      <div className="marquee marquee2">
          <div>
            <span className="marquee-space">sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦ sorteo ⇦</span>
            </div><br/>
      </div>
          <img className="teemo-img" src="img/teemo.svg" alt="Teemo apoyando y sonriendo"/>
    </div>
    </div>
    </div>

      </section>
      </Layout>
    )
}

export default Home;