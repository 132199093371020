import React from 'react';
import Layout from './components/Layout';
import Version from './components/Version';
import ChampsView from './components/Champs';

const RegistrationForm = () => {
  return (
    <Layout
      title="Tier List - Cetus Gaming"
      description="Descubre la tier list actualizada para Wild Rift en el parche Encuentra los mejores campeones para escalar y alcanzar Diamante. "
    >
      <section style={{ maxWidth: '1100px', alignItems: 'center' }} className="container h-section " >
        <div className=''>
          <h2 className='title-version'>Wild Rift Tier List</h2>
          <Version />
          <p className='p-champ '>
            Para el parche actual, los campeones de tiers S+ y S son los más dominantes según nuestros expertos, seguidos por la tier A para opciones sólidas pero menos destacadas, mientras que las tiers B y C son menos preferidas, con B siendo situacional y C los menos recomendados en el meta actual.</p>
          <ChampsView className="" />
        </div>
      </section>
    </Layout>
  );
};

export default RegistrationForm;
