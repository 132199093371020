import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



//import { subscribeUser } from '../services/subscribe';
const SuscribeteModal = ({ onClose, onSuscribete }) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      }, onClose());

      const data = await response.json();

      if (data.success) {
        onSuscribete();
        toast.success('¡Registro exitoso!');
        setTimeout(() => onClose(), 3000);
      } else {
        // Si el usuario ya está registrado, mostrar el mensaje
        if (data.error && data.error.includes('ya está suscrito')) {
          toast.error(data.error || 'Error al suscribirse');
          setMessage('¡Este usuario ya está registrado!');
        } else {
          setErrors(data.error || []);
        }
      }
    } catch (error) {
      toast.error('Error al suscribirse:', error.message);
    }
  };

  return (
    <Modal show={true} onHide={onClose}>
      <div className='bg-modal'>
      <Modal.Header closeButton>
        <Modal.Title><h4 className="pt-2" id="staticBackdropLabel">Suscribete a nuestro newsletter</h4></Modal.Title>
      </Modal.Header>
      <Modal.Body >
      <p>¡Únete a nuestra comunidad de Wild Rift y recibe las últimas novedades directamente en tu correo electrónico!</p>
        <input
          type="email"
          placeholder="Correo electrónico"
          value={email}
          name='email'
          onChange={(e) => setEmail(e.target.value)}
          style={{width:'100%', backgroundColor:'black', border:'none', color:'white'}}
        />

        {message && <div className="text-success">{message}</div>}

        {errors.map((error, index) => (
          <div key={index} className="text-danger">
            {error.text}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} >
          Cerrar
        </Button>
        <Button style={{paddingLeft:'20px'}} variant="primary" onClick={handleSubmit}>
          Suscribirse
        </Button>
      </Modal.Footer>
      </div>
      
    </Modal>
  );
};

export default SuscribeteModal;
