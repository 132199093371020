import React, { useEffect, useState } from 'react';

import ChampFilter from './Search';
import ChampList from './ChampsList';
import LoadingSpinner from './Spinner';
import Tab from 'react-bootstrap/Tab';

const ChampsView = () => {
  const [allChamps, setAllChamps] = useState([]);
  const [filteredChamps, setFilteredChamps] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeRole, setActiveRole] = useState('All');
  const [loading, setLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/champs`;
  
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAllChamps(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const filtered = allChamps.reduce((acc, champ) => {
      const matchingLanes = champ.lanes.filter(
        (lane) =>
          (activeRole === 'All' || lane.lane.toLowerCase() === activeRole.toLowerCase()) &&
          (!acc.some((c) => c.lane === lane.lane) || lane.tier > acc.find((c) => c.lane === lane.lane).tier)
      );
  
      if (matchingLanes.length > 0) {
        const champWithMatchingLanes = {
          ...champ,
          lanes: matchingLanes,
        };
  
        // Aplicar filtro searchTerm aquí
        if (
          champWithMatchingLanes.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          matchingLanes.some((lane) => lane.lane.toLowerCase().includes(searchTerm.toLowerCase()))
        ) {
          acc.push(champWithMatchingLanes);
        }
      }
  
      return acc;
    }, []);
  
    setFilteredChamps(filtered);
  }, [allChamps, searchTerm, activeRole]);
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 200) { // Cambia este valor según donde desees que se fije la barra
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
          <div className={`search-filters-wrapper position-${isScrolled ? 'sticky' : 'static'} top-0 mb-3 pt-3 bg-filters `} style={{ zIndex: 1000, width: '100%' }}>
        <ChampFilter setSearchTerm={setSearchTerm} setActiveRole={setActiveRole} />
      </div>
      <div className="mt-">
        <div className="sidebar"></div>
        <div className="content">
          <Tab.Container id="roles-tabs" defaultActiveKey="All" className="flex-column">
            <Tab.Content>
              <Tab.Pane eventKey="All">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="" style={{ position: 'relative', width: '100%' }}>
                      {loading && <LoadingSpinner />}
                      {!loading && filteredChamps.length > 0 ? (
                        <ChampList champs={filteredChamps} />
                      ) : !loading && filteredChamps.length === 0 ? (
                        <p style={{ textAlign: "left" }}>No se encontraron resultados</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>

  );
};

export default ChampsView;
