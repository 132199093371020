import React from 'react';

function Rules({ tournament }) {
  if (!tournament) {
    return <p>Cargando reglas del torneo...</p>;
  }


  return (
              <div>
              <p className='rules'>
                    Reglamento del Torneo Wild Rift 1 vs 1
              1. Inscripción y Participación
              1.1 Requisitos de Participación:

              Los jugadores deben tener una cuenta activa de Wild Rift.
              Todos los jugadores deben registrarse antes de la fecha límite de inscripción.
              1.2 Cuota de Inscripción:

              La inscripción al torneo puede ser gratuita o tener una cuota, según decida la organización.
              La cuota de inscripción (si existe) debe ser pagada antes de la fecha límite de inscripción.
              1.3 Datos de Inscripción:

              Los jugadores deben proporcionar su nombre de invocador, correo electrónico y cualquier otra información requerida por la organización.
              2. Formato del Torneo
              2.1 Estructura del Torneo:

              El torneo se llevará a cabo en formato de eliminación simple o doble, según lo determine la organización.
              Las partidas serán al mejor de tres (Bo3) en rondas clasificatorias y al mejor de cinco (Bo5) en las finales.
              2.2 Desempates:

              En caso de empate, se jugará una partida adicional para determinar al ganador.
              3. Configuración de la Partida
              3.1 Mapa:

              Todas las partidas se jugarán en el mapa Abismo de los Lamentos (ARAM).
              3.2 Condiciones de Victoria:

              La victoria se obtiene al:
              Destruir la primera torre enemiga.
              Obtener la primera sangre.
              Alcanzar 100 de farm (CS).
              3.3 Configuración de la Sala:

              Modo de juego: Abismo de los Lamentos.
              Tipo de partida: 1 vs 1.
              Sin restricciones de campeones.
              4. Reglas de Juego
              4.1 Prohibiciones:

              No se permite el uso de hacks, cheats o cualquier tipo de software de terceros que altere el juego.
              Cualquier forma de comportamiento antideportivo, incluyendo abuso verbal, trolling y sabotaje, está estrictamente prohibida.
              4.2 Desconexiones y Reanudaciones:

              Si un jugador se desconecta, la partida continuará. Es responsabilidad del jugador asegurarse de tener una conexión estable.
              En casos excepcionales, la organización puede decidir repetir una partida si la desconexión es atribuible a problemas del servidor.
              4.3 Protestas y Disputas:

              Las protestas deben presentarse inmediatamente después de finalizar la partida en cuestión.
              Las decisiones del árbitro del torneo son finales y vinculantes.
              5. Conducta y Deportividad
              5.1 Comportamiento:

              Se espera que todos los jugadores mantengan un comportamiento respetuoso y profesional en todo momento.
              Cualquier forma de acoso, discriminación o abuso resultará en la descalificación inmediata.
              5.2 Comunicación:

              La comunicación entre los jugadores debe ser respetuosa y enfocada en el juego.
              El uso de lenguaje ofensivo, insultante o provocativo está prohibido.
              6. Premios y Reconocimientos
              6.1 Distribución de Premios:

              La distribución de premios será anunciada antes del inicio del torneo.
              Los premios serán entregados en un plazo de [x] días después de la finalización del torneo.
              6.2 Reconocimientos:

              Los ganadores recibirán certificados o trofeos, según lo determine la organización.
              Los jugadores destacados pueden ser reconocidos en las redes sociales y sitios web oficiales del torneo.
              7. Modificaciones del Reglamento
              7.1 Cambios y Actualizaciones:

              La organización se reserva el derecho de modificar el reglamento en cualquier momento.
              Cualquier cambio en el reglamento será comunicado a los participantes con la debida anticipación.
              7.2 Interpretación del Reglamento:

              La interpretación del reglamento queda a discreción de la organización y sus árbitros.
              Las decisiones de la organización son finales y no están sujetas a apelación.
              Contacto
              Para cualquier duda o aclaración, por favor contacta a la organización del torneo a través de infoteamcetus@gmail.com

              </p>
                  </div>
                );
              }

              export default Rules;
