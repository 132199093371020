const Footer = ()=>{
    return(
        
    <>
        <div className="conatiner text-center footer">
            <div className="pt-2 pb-2 text-center ">
            <a href="https://www.facebook.com/cetusgg"><img className="social-iconf"  src="img/facebook.svg" alt=""/></a>
            <a href="https://www.instagram.com/cetusgg/"><img className="social-icon" src="img/instagram.svg" alt=""/></a>
            <a href="https://www.tiktok.com/@cetus_gaming"><img className="social-iconf" src="img/tiktok.svg" alt=""/></a>
            <a href="https://discord.gg/rJfDUgWUs9"><img className="social-icon" src="img/discord.svg" alt=""/></a>
            <a href="https://twitter.com/cetusgg"><img className="social-icon" src="img/twitter.svg" alt=""/></a>
        </div><br/><br/>

        <img className="pt-2 pb-2" src="cetus-gaming-logo-footer.png" alt=""/>
        <p className="p-footer">© 2023 Cetus Gaming. All rights reserved.</p>
        </div>
    </>
    )
}

export default Footer;
