import React from 'react';

const LoadingSpinner = () => (
  <div className="animation-container">
    <div className="loading-line">
      <div className="loading-bar">
      </div>
    </div>
  </div>
);

export default LoadingSpinner;
