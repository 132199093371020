import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from '../pages/components/NavBar';
import Home from '../pages/Home';
import Footer from '../pages/components/Footer'
import About from '../pages/About'
import Blog from '../pages/Blog'
import Tournaments from '../pages/Tournaments'


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Winner from '../pages/Winner';
import RegistrationForm from '../pages/ApiChamps';



function AppRouter (){ 
    
    
    return (
        <div className='App'>
            <BrowserRouter>
              <NavBar />
              <Routes>
                {/* Rutas Públicas */}
                <Route path='/cetus' element={<Home/>}/>
                <Route path='/' element={<Home/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/blog' element={<Blog/>}/>
                <Route path='/tierlist' element={<RegistrationForm/>}/>
                <Route path='/tournaments' element={<Tournaments/>}/>
                <Route path='/winner' element={<Winner/>}/>
              </Routes>
              <ToastContainer />
              <Footer/>
            </BrowserRouter>
        </div>
      );
    }
    
    export default AppRouter;
