import React from 'react';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';

const ChampFilter = ({ setSearchTerm, setActiveRole }) => {
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  return (
    <div className='padding'>
      <Form className="mb-4 search-bg">
        <Form.Group controlId="search">
          <Form.Control
            type="text"
            placeholder="Ingresa el nombre del campeón"
            onChange={handleSearchChange}
            className='search-bg'
          />
        </Form.Group>
      </Form>
      <Nav variant="pills" defaultActiveKey="All" onSelect={(selectedKey) => handleRoleChange(selectedKey)} className="mb-3">
        <Nav.Item >
       <Nav.Link className='nav-link-champs' eventKey="All"><img className='img-icon-search' src="img/all.webp" alt="icon-all-champs"/></Nav.Link>
     </Nav.Item>
     <Nav.Item>
       <Nav.Link className='nav-link-champs' eventKey="Top"><img className='img-icon-search' src="img/top.webp" alt="icon-top-champs"/></Nav.Link>
     </Nav.Item>
     <Nav.Item>
       <Nav.Link className='nav-link-champs' eventKey="Jungla"><img className='img-icon-search' src="img/jg.webp" alt="icon-jg-champs"/></Nav.Link>
     </Nav.Item>
     <Nav.Item>
       <Nav.Link className='nav-link-champs' eventKey="Mid"><img className='img-icon-search' src="img/mid.webp" alt="icon-mid-champs"/></Nav.Link>
     </Nav.Item>
     <Nav.Item>
       <Nav.Link className='nav-link-champs' eventKey="Inferior"><img className='img-icon-search' src="img/duo.webp" alt="icon-adc-champs"/></Nav.Link>
     </Nav.Item>
     <Nav.Item>
       <Nav.Link className='nav-link-champs' eventKey="Support"><img className='img-icon-search' src="img/supp.webp" alt="icon-supp-champs"/></Nav.Link>
     </Nav.Item>
   

   </Nav>
    </div>
  );
};

export default ChampFilter;
